var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (+_vm.UIVersion === 2)?_c('div',{staticClass:"tw-flex",class:{ 'tw-flex-col': _vm.$breakpoints.sm }},[(
      !['/', '/privacy-policy', '/terms-and-conditions', '/login', '/signup'].includes(
        _vm.$route.path
      )
    )?[_c('div',{ref:"topFixed",staticClass:"tw-fixed tw-top-0 tw-left-0 tw-right-0 tw-z-45"},_vm._l((_vm.topItems),function(item,index){
    var _obj;
return _c('div',{key:index},[(item.visible)?_c('div',{staticClass:"tw-text-center tw-font-gotham tw-text-sm",class:( _obj = {
            'tw-text-white tw-bg-mybid-primary': !item.style && !item.customStyle
          }, _obj[item.style] = item.style && !item.customStyle, _obj ),style:(item.customStyle || '')},[_c('span',{class:{
            'tw-flex tw-justify-center tw-items-center tw-gap-1': item.type && item.type !== 'custom',
          }},[(item.type && item.type !== 'custom')?[(item.type === 'success')?_c('SuccessIcon',{staticClass:"tw-w-4 tw-text-green-dark-6 dark:tw-text-green-light-7"}):_vm._e(),(item.type === 'warning')?_c('WarningIcon',{staticClass:"tw-w-4 tw-text-orange-light dark:tw-text-orange"}):_vm._e(),(item.type === 'error')?_c('ErrorIcon',{staticClass:"tw-w-4 tw-text-red-light-2 dark:tw-text-red-light-3"}):_vm._e(),(item.type === 'info')?_c('InfoIcon',{staticClass:"tw-w-4 tw-text-blue-light dark:tw-text-blue-light-1"}):_vm._e()]:_vm._e(),_c('span',[_vm._v(_vm._s(item.text))]),(item.link && item.linkText)?[(item.link.includes('http'))?_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_c('span',{staticClass:"tw-font-bold tw-underline tw-transition hover:tw-text-blue hover:tw-no-underline"},[_vm._v("\n                  "+_vm._s(item.linkText)+"\n                ")])]):_c('router-link',{attrs:{"to":{ path: item.link }}},[_c('span',{staticClass:"tw-font-bold tw-underline tw-transition hover:tw-text-blue hover:tw-no-underline"},[_vm._v("\n                  "+_vm._s(item.linkText)+"\n                ")])])]:_vm._e()],2)]):_vm._e()])}),0)]:_vm._e(),(
      !['/', '/privacy-policy', '/terms-and-conditions', '/login', '/signup'].includes(
        _vm.$route.path
      )
    )?[_c('AdblockDetect')]:_vm._e(),(!_vm.$route.meta.guest && _vm.isAuthenticated)?_c('Sidebar',{style:({
      'margin-top': (_vm.topFixedHeight + "px"),
    }),attrs:{"user":_vm.user,"metaData":_vm.metaData,"menuOpened":_vm.menuOpened,"permissions":{
      sidebar: {
        user_mediations: _vm.can('viewAny', 'user_mediations'),
        payment_requests: _vm.can('viewAny', 'payment_requests'),
        section_users: _vm.can('viewAny', 'section_users'),
        admin_payment_requests: _vm.can('viewAny', 'admin_payment_requests'),
        stats: _vm.can('viewAny', 'stats'),
        stats_adformat_rates: _vm.can('viewAny', 'stats_adformat_rates'),
        admin_accrual_rules: _vm.can('viewAny', 'admin_accrual_rules'),
        admin_mediations: _vm.can('viewAny', 'admin_mediations'),
        own_offers: _vm.can('viewAny', 'own_offers'),
        admin_own_offers: _vm.can('viewAny', 'admin_own_offers'),
        spot_options: _vm.can('viewAny', 'spot_options'),
        spot_optimization_rules: _vm.can('viewAny', 'spot_optimization_rules'),
        domain_moderations: _vm.can('viewAny', 'domain_moderations'),
        user_revshare_change_requests: _vm.can('viewAny', 'user_revshare_change_requests'),
        premoderations: _vm.can('viewAny', 'premoderations'),
        notification_manuallies: _vm.can('viewAny', 'notification_manuallies'),
        site_settings: _vm.can('viewAny', 'site_settings'),
        system_revshares: _vm.can('viewAny', 'system_revshares'),
        audits: _vm.can('viewAny', 'audits'),
        logs: _vm.can('viewAny', 'logs'),
        metas: _vm.can('viewAny', 'metas'),
        admin_panels: _vm.can('view', 'admin_panels'),
        admin_spots: _vm.can('viewAny', 'admin_spots'),
        landings: _vm.can('viewAny', 'landings'),
        admin_landings: _vm.can('viewAny', 'admin_landings'),
        referral: ['Clickadilla', 'MyBid', 'Onclicka'].includes(_vm.env.VUE_APP_ORG_NAME),
        losses: _vm.can('viewAny', 'publisher_fallout_data'),
      },
      notification: {
        update: _vm.can('update', 'notification_users'),
        viewAny: _vm.can('viewAny', 'notification_users'),
      },
    },"userManager":_vm.ownManager,"isDarkTheme":_vm.isDarkTheme,"notifications":_vm.menuNotifications,"isLoggedAsUser":!!_vm.LoginService.getLoginAsUser(),"notificationsCount":_vm.notificationsCount,"unmarkedNotifications":_vm.unmarkedNotifications},on:{"logout":_vm.logout,"actions":function (ref) {
        var type = ref.type;
        var notification = ref.notification;

        return _vm.actions[type](notification);
},"toggle-menu":_vm.expandMenu,"toggle-theme":_vm.changeTheme,"get-notifications":function (query) { return _vm.getNotifications(Object.assign({}, query, {section: 'menu'})); },"mark-notifications":_vm.markNotifications,"reset-user":_vm.resetUser,"get-notification-body":_vm.getNotificationBody}}):_vm._e(),_c('Snackbar'),_c('div',{staticClass:"tw-w-screen tw-overflow-auto tw-bg-white dark:tw-bg-mybid-dark",style:({
      'margin-top': (_vm.topFixedHeight + "px"),
    })},[(!_vm.$route.meta.redesign)?_c('v-app',[_c('v-main',{class:[].concat( (_vm.$vuetify.theme.dark ? [] : ['grey lighten-3']) )},[_c('router-view')],1)],1):_c('router-view')],1),_c('WelcomePopup')],2):(_vm.$route.meta.landing)?_c('router-view'):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }