




































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component({
  name: 'Loader',
})
export default class Loader extends Vue {
  @Prop({ default: 'auto' }) size!: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'auto';
  @Prop({
    default: 'spinning',
    type: String,
  })
  animate!: 'spinning' | 'progress';

  @Getter isDarkTheme!: boolean;

  get _env() {
    return process.env;
  }

  get isClickadilla() {
    return this._env.VUE_APP_ORG_NAME === 'Clickadilla';
  }

}
