var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-flex-col",class:( _obj = {
    'tw-shadow-sm': _vm.shadow === 'sm',
    'tw-shadow-md': _vm.shadow === 'md',
    'tw-shadow-lg': _vm.shadow === 'lg',
    'tw-shadow-xl': _vm.shadow === 'xl',
    'tw-shadow-2xl': _vm.shadow === '2xl'
  }, _obj[("tw-bg-" + _vm.background)] = _vm.background && _vm.background.length, _obj['tw-text-black dark:tw-text-white'] =  _vm.env.VUE_APP_ORG_NAME !== 'Clickadilla', _obj['tw-text-mybid-primary'] =  _vm.env.VUE_APP_ORG_NAME === 'Clickadilla', _obj['tw-bg-white dark:tw-bg-mybid-gray-dark'] = 
      !_vm.background && !['Onclicka', 'Clickadilla'].includes(_vm.env.VUE_APP_ORG_NAME), _obj['tw-bg-white dark:tw-bg-mybid-gray-dark-2'] = 
      !_vm.background && _vm.env.VUE_APP_ORG_NAME === 'Clickadilla', _obj['dark:tw-bg-mybid-dark'] =  !_vm.background && _vm.env.VUE_APP_ORG_NAME === 'Onclicka', _obj['tw-border tw-border-gray-light dark:tw-border-mybid-gray'] =  _vm.outlined, _obj[("tw-w-" + _vm.width)] = _vm.width, _obj['tw-w-auto'] =  !_vm.width, _obj['tw-rounded-md'] =  _vm.rounded, _obj ),on:{"click":_vm.click}},[_c('div',[_vm._t("header")],2),_c('div',{class:_vm.className},[_vm._t("default")],2),_c('div',[_vm._t("actions")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }