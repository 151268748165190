




















































import { Component, Vue } from 'vue-property-decorator';

import Button from '@/components/v2/Button.vue';

@Component({
  name: 'Snackbar',
  components: { Button },
})
export default class Snackbar extends Vue {
  type = '';
  title = '';
  message = '';
  snackbar = false;

  get isMobile() {
    return window.innerWidth <= 430;
  }

  mounted() {
    this.$notify.$on('error', (params: any) => {
      this.type = 'error';
      this.show(params);
    });
    this.$notify.$on('info', (params: any) => {
      this.type = 'info';
      this.show(params);
    });
    this.$notify.$on('success', (params: any) => {
      this.type = 'success';
      this.show(params);
    });
    this.$notify.$on('close', () => (this.snackbar = false));
  }

  show(params: any) {
    try {
      this.setSnackbarParams(params);
      this.snackbar = true;
      if (this.isMobile) {
        setTimeout(() => {
          this.snackbar = false;
        }, 1000);
      }
    } catch (err) {
      console.error(err);
    }
  }

  setSnackbarParams(params: any) {
    if (typeof params === 'string') {
      this.message = params;
      return false;
    }
    this.message = this.type === 'error' ? this.parseErrorObj(params) : params.message;
    this.title = params.title || params.type;
    return false;
  }

  parseErrorObj(error: any) {
    if (error && error.customMessage) return error.customMessage;
    else {
      if (Object.keys(error).length && !error.isAxiosError)
        return Object.keys(error).reduce(
          (acc, cur, i) => acc + (i !== 0 ? '\n' : '') + error[cur],
          ''
        );
      else return this.notUserError(error);
    }
  }

  notUserError(error: any) {
    throw error;
  }
}
