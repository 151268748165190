import { IAuthResponse, ILoginPayload, ISignUpPayload } from '@/interfaces';
import router from '@/router';
import AdminService from '@/services/AdminService';
import ApiService from '@/services/ApiService';
import { LoginService } from '@/services/StorageService';
import TokenService from '@/services/TokenService';
import { can } from '@/store/helpers';

class AuthService {
  async login(payload: ILoginPayload) {
    try {
      const { data } = await ApiService.http.post<void, { data: IAuthResponse }>(
        '/api/auth/login',
        payload
      );
      TokenService.setTokenToServices(data?.access_token);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async refresh() {
    try {
      const { history } = router as any;
      const userId = history?.pending?.query?._user || LoginService.getLoginAsUser();
      let res = await ApiService.http.post<void, { data: IAuthResponse }>('/api/auth/refresh');
      (ApiService as any).addHeader({
        name: 'Authorization',
        value: `Bearer ${res?.data?.access_token}`,
      });
      if (userId) {
        res = await AdminService.getUserToken(userId);
        LoginService.setLoginAsUser(userId);
      } else {
        LoginService.removeLoginAsUser();
      }
      TokenService.setTokenToServices(res?.data?.access_token);
      return res.data;
    } catch (error) {
      throw error;
    }
  }

  async register(payload: ISignUpPayload) {
    try {
      const { data } = await ApiService.http.post<void, { data: IAuthResponse }>(
        '/api/auth/register',
        payload
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async registerByInvitation(payload: ISignUpPayload) {
    try {
      const { data } = await ApiService.http.post<void, { data: IAuthResponse }>(
        '/api/auth/register-by-invitation',
        payload
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  async logout() {
    try {
      await ApiService.http.post<void, { data: { message: string } }>('/api/auth/logout');
      TokenService.removeTokenFromServices();
      if (can('viewAny', 'notification_users')) {
        TokenService.disconnectSocket();
      }
    } catch (error) {
      throw error;
    }
  }
}

export default new AuthService();
