















































































































































import { Component, Mixins, Prop } from 'vue-property-decorator';

import Badge from '@/components/v2/Badge.vue';
import Button from '@/components/v2/Button.vue';
import Card from '@/components/v2/Card.vue';
import ExpansionPanel from '@/components/v2/ExpansionPanel.vue';
import Modal from '@/components/v2/Modal.vue';
import Tooltip from '@/components/v2/Tooltip.vue';
import { INotification } from '@/interfaces';
import NotificationMixin from '@/mixins/notificationMixin';
import { YMDHMS } from '@/utils/constants';
import { formatDate } from '@/utils/dates';

@Component({
  name: 'SidebarNotifications',
  filters: {
    formatDate,
  },
  components: {
    Badge,
    Button,
    Card,
    Modal,
    Tooltip,
    ExpansionPanel,
  },
})
export default class SidebarNotifications extends Mixins(NotificationMixin) {
  @Prop({ required: true }) menuOpened!: boolean;
  @Prop({ required: true }) permissions!: Record<'update', boolean>;

  YMDHMS = YMDHMS;
  dialog = false;
  activeType: INotification['type'] | null = null;

  get groups() {
    return this.notificationsCount.data.reduce(
      (acc, { type, count }) => ({ ...acc, [`${type}`]: +count > 0 }),
      {}
    );
  }

  get env() {
    return process.env;
  }

  get isClickadilla() {
    return process.env.VUE_APP_ORG_NAME === 'Clickadilla'
  }

  changeDialog(dialog: boolean) {
    if (!dialog) {
      this.changeActiveType(false, null);
    }
    this.dialog = dialog;
  }

  changeActiveType(isActive: boolean, type: INotification['type'] | null) {
    this.activeType = isActive ? type : null;
  }
}
