
























































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import Button from '@/components/v2/Button.vue';
import EmailIcon from '@/components/v2/Icons/EmailIcon.vue';
import SkypeIcon from '@/components/v2/Icons/SkypeIcon.vue';
import TelegramIcon from '@/components/v2/Icons/TelegramIcon.vue';
import { Contact, IUserExtended, Manager } from '@/interfaces';
import { can } from '@/store/helpers';
import { capitalizeFirstLetter } from '@/utils/filters';

@Component({
  name: 'Info',
  components: {
    EmailIcon,
    SkypeIcon,
    TelegramIcon,
    Button,
  },
})
export default class Info extends Vue {
  @Prop({ default: null }) userManager!: Manager | null;
  @Prop({ default: false, type: Boolean }) isAlternative!: boolean;
  @Prop({ default: true, type: Boolean }) shouldShowHelpText!: boolean;
  @Prop({ default: true, type: Boolean }) shouldShowLinkText!: boolean;

  @Getter fullUserName!: IUserExtended['first_name'] & IUserExtended['last_name'];
  @Getter ownManager!: Manager | null;
  icons = {
    email: EmailIcon,
    skype: SkypeIcon,
    telegram: TelegramIcon,
  };
  defaultManager = {
    first_name: '',
    last_name: '',
    nickname: '',
    email: process.env.VUE_APP_ORG_EMAIL,
    contacts: [
      { name: 'telegram', value: process.env.VUE_APP_ORG_TELEGRAM },
      { name: 'skype', value: process.env.VUE_APP_ORG_SKYPE },
    ],
  };

  get manager() {
    return this.userManager ?? this.defaultManager;
  }

  get isPubWithNoManager () {
    return process.env.VUE_APP_ORG_NAME === 'MyBid' && !this.userManager && !can('view', 'admin_panels')
  }

  generateContact(contact: Contact) {
    const dictionary: Dictionary<{
      href: string;
      text: Contact['value'];
    }> = {
      email: {
        href: this.isPubWithNoManager ? 'mailto:marco@pub-mybid.io' : `mailto:${contact.value}?subject=${process.env.VUE_APP_ORG_NAME} (${this.fullUserName})`,
        text: this.isPubWithNoManager ? 'marco@pub-mybid.io' : contact.value,
      },
      skype: {
        href: `skype:${contact.value}?chat`,
        text: process.env.VUE_APP_ORG_NAME === 'Onclicka' ? 'Onclicka Support' : contact.value,
      },
      telegram: {
        href: `https://t.me/${contact.value.replace('@', '')}`,
        text: contact.value.replace('@', ''),
      },
    };
    return dictionary[contact.name];
  }

  buttonText(contact: Contact) {
    const { name } = contact;
    const displayName = contact.name === 'email' ? 'E-mail' : name;

    return this.shouldShowLinkText
      ? this.generateContact(contact).text
      : capitalizeFirstLetter(displayName);
  }
}
