










import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'SuccessIcon',
})
export default class SuccessIcon extends Vue {}
