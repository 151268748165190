














import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'InfoIcon',
})
export default class InfoIcon extends Vue {}
