var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-inline-block tw-font-gotham"},[_vm._t("activator"),_c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.value),expression:"value"}],class:['tw-relative tw-z-50' ].concat( (_vm.value ? ['tw-block'] : ['tw-hidden'])),attrs:{"role":"dialog","aria-modal":"true"}},[_c('div',{staticClass:"tw-fixed tw-transition-opacity",class:{
          'tw-bg-gray tw-bg-opacity-50': _vm.isOnclicka,
          'tw-bg-mybid-black tw-opacity-90': _vm.isClickadilla && !_vm.mobileLoader,
          'tw-bg-opacity-50': !_vm.isClickadilla,
          'tw-bg-blue-900 tw-bg-opacity-50': !_vm.isOnclicka && !_vm.isClickadilla,
          'tw-inset-0 tw-bg-opacity-50': !_vm.downPosition && !_vm.mobileLoader,
          'tw-inset-0 tw-bottom-0 tw-pointer-events-auto tw-bg-black tw-opacity-90 tw-w-full': _vm.downPosition,
          'tw-inset-0 tw-opacity-90 tw-bg-black-total-dark tw-w-full': _vm.mobileLoader && _vm.isClickadilla          }}),_c('div',{staticClass:"tw-fixed",class:{'tw-inset-0 tw-z-50 tw-overflow-y-auto': !_vm.downPosition,
                 'tw-bottom-0 tw-left-0 tw-w-full': _vm.downPosition,
                 'tw-p-15 xxl:tw-p-10 max-440:tw-p-0': _vm.isClickadilla && !_vm.downPosition && !_vm.mobileLoader,
                 'tw-p-4': !_vm.isClickadilla,
                 'tw-inset-0 tw-w-full': _vm.mobileLoader && _vm.isClickadilla,
        }},[_c('div',{staticClass:"tw-flex tw-w-full tw-min-h-full tw-text-center",class:{'tw-p-4 sm:tw-p-0 tw-justify-center tw-items-center sm:tw-items-center ': !_vm.downPosition && !_vm.mobileLoader,
                   'tw-p-0 tw-justify-end tw-items-end': _vm.downPosition,
                   'max-440:tw-p-0 max-440:tw-overflow-x-hidden': _vm.isClickadilla && !_vm.downPosition,
                   'tw-justify-center tw-items-center': _vm.mobileLoader && !_vm.downPosition,
          },on:{"mousedown":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.clickOutside()}}},[_c('div',{staticClass:"tw-relative",class:{
              'tw-w-full': _vm.widthFull,
            },style:({ minWidth: _vm.minWidth, maxWidth: _vm.maxWidth })},[_vm._t("default"),(_vm.hasCloseButton)?_c('Button',{staticClass:"tw-absolute tw-top-4 tw-right-4 sm:tw-top-12.5 sm:tw-right-12.5",attrs:{"size":"sm","icon":"","rounded":"","color":"research"},on:{"click":_vm.clickClose}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"16","height":"16","viewBox":"0 0 16 16","fill":"none"}},[_c('g',{attrs:{"clip-path":"url(#clip0_1079_4194)"}},[_c('path',{attrs:{"d":"M11.6117 2.94975C11.9534 2.60804 12.5075 2.60804 12.8492 2.94975C13.1909 3.29146 13.1909 3.84548 12.8492 4.18718L4.1871 12.8492C3.8454 13.191 3.29138 13.191 2.94967 12.8492C2.60796 12.5075 2.60796 11.9535 2.94967 11.6118L11.6117 2.94975Z","fill":"currentColor"}}),_c('path',{attrs:{"d":"M12.8492 11.6118C13.1909 11.9535 13.1909 12.5075 12.8492 12.8492C12.5075 13.191 11.9534 13.191 11.6117 12.8492L2.94967 4.18718C2.60796 3.84547 2.60796 3.29146 2.94967 2.94975C3.29138 2.60804 3.8454 2.60804 4.1871 2.94975L12.8492 11.6118Z","fill":"currentColor"}})]),_c('defs',[_c('clipPath',{attrs:{"id":"clip0_1079_4194"}},[_c('rect',{attrs:{"width":"16","height":"16","fill":"currentColor"}})])])])]):_vm._e(),(_vm.hasDoneButton)?_c('div',{staticClass:"tw-absolute tw-bottom-4 tw-left-4 sm:tw-bottom-12.5 sm:tw-left-12.5"},[_c('Button',{attrs:{"text":"","size":"md","color":"primary"},on:{"click":_vm.clickClose}},[_vm._v("\n                Done\n              ")])],1):_vm._e()],2)])])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }