import { ITagPermissions } from '@/interfaces';

const model: ITagPermissions = {
  spot: {
    delete: false,
    restore: false,
    updateAny: false,
    seeOptimization: false,
    seeMoneyRedirection: false,
    updateHidden: false,
  },
  admin_spot_options: {
    updateRedirectChrome: false,
    updateABTestingInpagePush: false,
    updateABTestingPopunder: false,
    updateAppearanceEventInpagePush: false,
    updateBetterAdsInpagePush: false,
    updateBetterAdsMobileInpagePush: false,
    updateBrandingInpagePush: false,
    updateDefZoneIdInpagePush: false,
    updateIsPositionLockedInpagePush: false,
    updateIsNotifyAnimationInpagePush: false,
    updateLargeCrossButtonInpagePush: false,
    updateMlCloseRatioInpagePush: false,
    updateMlCloseRatioModalInpagePush: false,
    updateParamsToExtendInpagePush: false,
    updatePlacementsWithOutsideCloseInpagePush: false,
    updateProxyDomainInpagePush: false,
    updateStartOnFullscreenEndInpagePush: false,
    updateSuggestiveInpagePush: false,
    updateTabunderInpagePush: false,
    updateTargetingInpagePush: false,
    updateABTestingTelegramAppInpagePush: false,
    updateAppearanceEventTelegramAppInpagePush: false,
    updateBetterAdsTelegramAppInpagePush: false,
    updateBetterAdsMobileTelegramAppInpagePush: false,
    updateBrandingTelegramAppInpagePush: false,
    updateDefZoneIdTelegramAppInpagePush: false,
    updateIsPositionLockedTelegramAppInpagePush: false,
    updateIsNotifyAnimationTelegramAppInpagePush: false,
    updateLargeCrossButtonTelegramAppInpagePush: false,
    updateMlCloseRatioTelegramAppInpagePush: false,
    updateMlCloseRatioModalTelegramAppInpagePush: false,
    updateParamsToExtendTelegramAppInpagePush: false,
    updatePlacementsWithOutsideCloseTelegramAppInpagePush: false,
    updateProxyDomainTelegramAppInpagePush: false,
    updateStartOnFullscreenEndTelegramAppInpagePush: false,
    updateSuggestiveTelegramAppInpagePush: false,
    updateTabunderTelegramAppInpagePush: false,
    updateTargetingTelegramAppInpagePush: false,
    updateIsUrlHashingTelegramAppInpagePush: false,
    updateCloseRadiusTelegramAppInpagePush: false,
    updateCloseButtonResizeByTelegramAppInpagePush: false,
    updateCustomSettingsTelegramAppInpagePush: false,
    updateServiceWorkerPathPush: false,
    updateTargetingPush: false,
    updateTargetingCalendar: false,
    updateExoclickZoneIdPopunder: false,
    updateFriendlyGooglePopunder: false,
    updateFriendlyYandexPopunder: false,
    updateFriendlyYandexInterstitial: false,
    updatePixelModePopunder: false,
    updateBackButtonDirectPopunder: false,
    updateDelayPerFirstPopPopunder: false,
    updateSspIdPopunder: false,
    updateSmartCappingPopunder: false,
    updateOverlayPopunder: false,
    updateTargetingPopunder: false,
    updateCloseRatioInStreamAd: false,
    updateAutoRepeatInStreamAd: false,
    updateExoclickZoneIdInStreamAd: false,
    updateRefreshDelayInStreamAd: false,
    updateRefreshQuantityInStreamAd: false,
    updateSequenceInStreamAd: false,
    updateSspIdInStreamAd: false,
    updateTargetingInStreamAd: false,
    updateMagmaBrandingInStreamAd: false,
    updateMagmaBrandingTelegramAppVideo: false,
    updateFrequencyDelaySecTelegramAppVideoOnClickA: false,
    updateCloseRatioTelegramAppVideo: false,
    updateSspIdTelegramAppVideo: false,
    updateRefreshDelayTelegramAppVideo: false,
    updateRefreshQuantityTelegramAppVideo: false,
    updateSequenceTelegramAppVideo: false,
    updateExoclickZoneIdTelegramAppVideo: false,
    updateIsUrlHashingTelegramAppVideo: false,
    updateDisableCtaTelegramAppVideo: false,
    updateCustomSettingsTelegramAppVideo: false,
    updateAntiblockRefreshVisibilityAreaBanner: false,
    updateExoclickZoneIdBanner: false,
    updateHasSplitTestsBanner: false,
    updateIsAntiblockRefreshBanner: false,
    updateMultipleBanner: false,
    updatePlacementTypeIdBanner: false,
    updateRefreshFrequencyBanner: false,
    updateRefreshTypeBanner: false,
    updateReplacementSpotIdBanner: false,
    updateSmartRefreshBanner: false,
    updateSspIdBanner: false,
    updateTargetingBanner: false,
    updateAntiblockRefreshVisibilityAreaTelegramAppBanner: false,
    updateExoclickZoneIdTelegramAppBanner: false,
    updateHasSplitTestsTelegramAppBanner: false,
    updateIsAntiblockRefreshTelegramAppBanner: false,
    updateMultipleTelegramAppBanner: false,
    updatePlacementTypeIdTelegramAppBanner: false,
    updateRefreshFrequencyTelegramAppBanner: false,
    updateRefreshTypeTelegramAppBanner: false,
    updateReplacementSpotIdTelegramAppBanner: false,
    updateSmartRefreshTelegramAppBanner: false,
    updateSspIdTelegramAppBanner: false,
    updateTargetingTelegramAppBanner: false,
    updateCustomSettingsTelegramAppBanner: false,
    updateIsUrlHashingTelegramAppBanner: false,
    updateRefreshNative: false,
    updateBetterAdsNative: false,
    updateTargetingNative: false,
    updateTargetingInterstitial: false,
    updateSspIdInterstitial: false,
    updateZeroSkipTimeInterstitial: false,
    updateTargetingDirectLink: false,
    banSpot: false,
    updateIsUrlHashingNative: false,
    updateIsUrlHashingPopunder: false,
    updateIsUrlHashingInStreamAd: false,
    updateDisableCtaInStreamAd: false,
    updateIsUrlHashingBanner: false,
    updateIsUrlHashingInterstitial: false,
    updateIsUrlHashingInpagePush: false,
    updateIsUrlHashingPush: false,
    updateIsUrlHashingCalendar: false,
    updateIsUrlHashingMobileApp: false,
    updateIsUrlHashingDirectLink: false,
    updateIsUrlHashingExtension: false,
    updatePostBackUrlPush: false,
    updatePostBackUrlPopunder: false,
    updateBackButtonLinkPush: false,
    updateUnsupportedLinkPush: false,
    updateAlreadySubscribedLinkPush: false,
    updateDeniedLinkPush: false,
    updateAllowedLinkPush: false,
    updateSetUpTrafficBackPush: false,
    updateLandingTypePush: false,
    updateCloseButtonResizeByInpagePush: false,
    updateCloseRadiusInpagePush: false,
    updateCustomSettingsInpagePush: false,
    updateCustomSettingsInStreamAd: false,
    updateCustomSettingsBanner: false,
    updateCustomSettingsPopunder: false,
    updateSubInterstitialSettingsPush: false,
    updatePostBackClickIdPush: false,
    updateDeepLink: false,
  },
};

export default model;
