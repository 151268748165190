var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"minWidth":"525px","maxWidth":"525px","value":_vm.dialog},on:{"click-outside":function($event){return _vm.changeDialog(!_vm.dialog)}},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('button',{staticClass:"tw-flex tw-items-center tw-gap-2.5 tw-w-full tw-px-5 tw-py-4 tw-transition tw-text-black hover:tw-bg-gray-light",class:{
        'dark:tw-text-black dark:tw-bg-gray-light dark:hover:tw-text-mybid-primary':
          _vm.env.VUE_APP_ORG_NAME === 'Onclicka',
        'dark:tw-text-white dark:tw-bg-mybid-black dark:hover:tw-bg-mybid-gray':
          _vm.env.VUE_APP_ORG_NAME !== 'Onclicka',
        'tw-justify-center': _vm.menuOpened,
      },on:{"click":function($event){return _vm.changeDialog(!_vm.dialog)}}},[_c('svg',{staticClass:"tw-fill-current",attrs:{"width":"14","height":"14","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M20,8L12,13L4,8V6L12,11L20,6M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4Z"}})]),(!_vm.menuOpened)?_c('span',{staticClass:"tw-flex tw-flex-col tw-items-start tw-font-gotham tw-text-sm"},[_vm._v("\n        Notifications\n      ")]):_vm._e(),(!_vm.menuOpened && _vm.unmarkedNotifications.length > 0)?_c('Badge',{attrs:{"inline":"","content":_vm.unmarkedNotifications.length > 99 ? '99+' : _vm.unmarkedNotifications.length}}):_vm._e()],1)]},proxy:true},{key:"default",fn:function(){return [_c('Card',{staticClass:"tw-p-12.5 tw-w-full",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.changeActiveType(false, null)}},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"tw-flex tw-items-center tw-justify-between"},[_c('h3',{staticClass:"tw-font-gotham tw-text-xl tw-font-bold",class:{'tw-text-mybid-secondary': !_vm.isClickadilla,
                 'dark:tw-text-white tw-text-black': _vm.isClickadilla,
        }},[_vm._v("\n            Notifications\n          ")]),_c('Button',{attrs:{"text":"","color":_vm.unmarkedNotifications.length ? 'primary-light' : 'secondary-light',"disabled":(!_vm.permissions.update && !_vm.unmarkedNotifications.length) || _vm.menuNotifications.loading},on:{"click":function($event){return _vm.markNotifications(_vm.unmarkedNotifications)}},scopedSlots:_vm._u([{key:"append-icon",fn:function(){return [_c('svg',{staticClass:"tw-fill-current",attrs:{"width":"14","height":"14","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M0.41,13.41L6,19L7.41,17.58L1.83,12M22.24,5.58L11.66,16.17L7.5,12L6.07,13.41L11.66,19L23.66,7M18,7L16.59,5.58L10.24,11.93L11.66,13.34L18,7Z"}})])]},proxy:true}])},[_c('span',[_vm._v("Mark as read")])])],1)]},proxy:true},{key:"default",fn:function(){return [_c('div',{staticClass:"tw-grid tw-gap-2 tw-pt-4"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.unmarkedNotifications.length),expression:"!unmarkedNotifications.length"}]},[_c('span',[_vm._v("No unread notifications")])]),_vm._l((_vm.notificationsCount.data),function(item){return _c('ExpansionPanel',{directives:[{name:"show",rawName:"v-show",value:(_vm.unmarkedNotifications.filter(function (ref) {
              var type = ref.type;

              return type === item.type;
        }).length > 0),expression:"unmarkedNotifications.filter(({ type }) => type === item.type).length > 0"}],key:item.type,attrs:{"dense":"","value":_vm.activeType === item.type},on:{"change":function (value) { return _vm.changeActiveType(value, item.type); }},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('div',{staticClass:"tw-flex tw-justify-center tw-items-center"},[_c('span',{staticClass:"tw-font-gotham tw-text-sm"},[_vm._v("\n                  "+_vm._s(item.title)+" ("+_vm._s(_vm.unmarkedNotifications.filter(function (ref) {
              var type = ref.type;

              return type === item.type;
        }).length)+")\n                ")]),_c('Badge',{staticClass:"tw-mt-10",attrs:{"dot":""}})],1)]},proxy:true},{key:"content",fn:function(){return [_c('transition',{attrs:{"name":"slide"}},[(!_vm.menuNotifications.loading)?_c('div',{staticClass:"tw-absolute tw-z-50 tw-w-96 tw-bg-white dark:tw-bg-mybid-gray-dark tw-max-h-72 tw-overflow-y-scroll"},_vm._l((_vm.unmarkedNotifications.filter(
                      function (ref) {
                            var type = ref.type;

                            return type === item.type;
        }
                    )),function(notification){return _c('div',{key:notification.id,staticClass:"tw-grid tw-grid-cols-12 tw-w-auto tw-justify-center tw-items-center tw-py-1"},[_c('div',[(_vm.permissions.update)?_c('Tooltip',{staticClass:"tw-mt-5 tw-pr-5",attrs:{"text":"Mark as read"}},[_c('Button',{attrs:{"icon":""},on:{"click":function($event){return _vm.markNotifications([notification])}}},[_c('Badge',{attrs:{"dot":""}})],1)],1):_vm._e()],1),_c('div',{staticClass:"tw-col-span-11 tw-cursor-pointer tw-pr-3",on:{"click":function($event){_vm.actions[notification.type || 'default'](
                          notification,
                          _vm.changeDialog(!_vm.dialog)
                        )}}},[(notification.header)?_c('h4',{staticClass:"tw-font-gotham tw-text-base tw-font-bold tw-overflow-hidden hyphens-auto tw-break-words"},[_vm._v("\n                        "+_vm._s(notification.header)+"\n                      ")]):_vm._e(),_c('p',{staticClass:"tw-font-gotham tw-text-sm tw-overflow-hidden hyphens-auto tw-text-justify tw-break-words text-align-last-center",domProps:{"innerHTML":_vm._s(_vm.getNotificationBody(notification))}}),_c('p',{staticClass:"tw-font-gotham tw-text-sm tw-text-gray"},[_vm._v("\n                        • "+_vm._s(_vm._f("formatDate")(notification.created_at,_vm.YMDHMS))+"\n                      ")])])])}),0):_vm._e()])]},proxy:true}],null,true)})})],2)]},proxy:true}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }